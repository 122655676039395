import React from "react";
import { MdSupportAgent, MdEmail } from "react-icons/md";
import { IoSend } from "react-icons/io5";


function Contact() {

    return (
        <section className="contact" >
          <div className="contact-search-area">
            <div className="contact-image" />
            <div className="contact-text">
              <p className="contact-title">İletişim</p>
            </div>
          </div>
          <div className="container my-2">
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                    <MdEmail fontSize={30} />
                    <p className="footer_email">E-Posta</p>
                    <p className="footer_mail_text">Aklınıza takılan sorulara samimi cevaplar almak için bize yazmaktan çekinmeyiniz.</p>
                    <a href="mailto:name@email.com"><p className="footer_mail_info"><IoSend className="mr-2"/>info@insanizi.org</p></a>

                    <MdSupportAgent fontSize={30}  />
                    <p className="footer_email">İletişim</p>
                    <p>Bizimle iletişim kurmaktan çekinmeyiniz.</p>
                    <a href="tel:08505322540"><p className="font-weight-bold">0 850 532 25 40</p></a>
                </div>
                <div className="col-sm-12 col-lg-6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d752.5291398390912!2d29.0086561!3d41.0227058!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab956196169ff%3A0x7244aed63437ff57!2zxLBOU0FOIMSwWsSwIFVMVVNMQVJBUkFTSSDEsE5TQU7EsCBZQVJESU0gREVSTkXEnsSw!5e0!3m2!1str!2str!4v1653239025696!5m2!1str!2str" width="90%" height="450" style={{"border":0, padding: "2px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
          </div>
        </section>
      );
}
export default Contact;
