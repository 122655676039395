import React from "react";
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import kurban1 from "../assets/img/kurbanCalismasi.webp";
import kurban2 from "../assets/img/kurban22.jpg";
import kurban3 from "../assets/img/kurban33.jpg";
import kurban4 from "../assets/img/kurban44.jpg";
import { Accordion, AccordionTab } from 'primereact/accordion';

import photo1 from "../assets/img/kurban/Resim1.jpg";
import photo2 from "../assets/img/kurban/web-için-kolaj-1.jpg";
import photo3 from "../assets/img/kurban/web-için-kolaj-2.jpg";
import photo4 from "../assets/img/kurban/web-için-1.jpg";
import photo5 from "../assets/img/kurban/web-için-2.jpg";



function Kurban() {

  return (
    <section className="kurban" >
      <div className="kurban-search-area">
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="kurban-description">
              <b>İnsan İzi</b> olarak, yılların vermiş olduğu tecrübeyle <b>Afrika’da kurbanlarınızı ibadet hassasiyetiyle kesiyor,</b> yine sizler adına etlerini ihtiyaç sahibi kardeşlerimize ulaştırıyoruz.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center ">
            <Button label="Kurban Bağışı Yap" onClick={() => window.location.href = "/"} className="p-button-sm kurban-button" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center">
            <Image src={photo1} alt="kurban" className="kurban_image" preview />
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center ">
            <p className="kurban-title">Kurban Seninle Her Yerde</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center ">
            <p className="kurban-description">
              <b>İnsan İzi güvenilirliği ile</b> yapacağınız 2024 kurban organizasyonu ile emanetleriniz gerçek <b>ihtiyaç sahibi coğrafyalarda bayram sevincine dönüşüyor.</b> Bu yıl da <b>Kurban Seninle Her Yerde</b> diyerek yola çıkıyor, önceki yıllarda olduğu gibi ihtiyaç sahiplerine sizlerin aracılığıyla dokunuyoruz. 
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center ">
            <Button label="Kurban Bağışı Yap" className="p-button-sm kurban-button" onClick={() => window.location.href = "/"} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center">
            <Image src={photo2} alt="kurban" className=" kurban_image" preview />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12 flex justify-content-center ">
            <p className="kurban-title">Afrika Kurban Hisse Bedeli Ne Kadar?</p>
          </div>
        </div> */}
        <div className="row">
          <div className="col-12 flex justify-content-center ">
            <p className="kurban-description">
              Onlarca farklı noktada kesim ve dağıtım operasyonu planlayan İnsan İzi emanetlerinizi ulaştırmanın haklı gururunu yaşarken kurbanınızın seçim, kesim, dağıtım gibi görüntülerinin tamamını <b>kayıt altına alarak</b> Kurban Bayramı sonrası bağışçılarıyla da paylaşıyor. İnsan İzi olarak Kurban Bayramı operasyonlarımızda İslami hassasiyeti gözeterek ve güvenilirliği esas kabul ederek kurban emanetlerinizi mazlumlara ulaştırıyoruz. 
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center ">
            <Button label="Kurban Bağışı Yap" className="p-button-sm kurban-button" onClick={() => window.location.href = "/"} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center">
            <Image src={photo3} alt="kurban" className=" kurban_image" preview />
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center ">
            <p className="kurban-title">Afrika’da Kurban Bedeli Nedir?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center ">
            <p className="kurban-description">
              İnsan İzi 16 Haziran 2024 Pazar - 19 Haziran 2024 Çarşamba gününü kapsayan 2024 yılı Kurban Bayramı için hazırlıklarını aylar öncesinden başlattı. Sizlerin emanetleri olan kurbanlıkların seçimleri İslami hassasiyetlere göre yapılırken, ihtiyaç sahibi dağıtım yapılacak olan bölgeler ise bayram öncesinde tespit edildi. <b>İnsan İzi, 2024 yılı Kurban Bayramında Afrika'da gerçekleştireceği vekalet yoluyla kurban kesimi için büyükbaş hisse bedelini 3500 ₺ olarak belirledi.</b>
            </p>
          </div>
          <div className="col-12 flex justify-content-center ">
            <p className="kurban-description">
              İnsan İzi olarak bu yıl da geçtiğimiz yıllarda olduğu gibi bağışçılarımızın taleplerini göz önünde bulundurarak küçükbaş kurban hazırlığını da yapmış bulunuyoruz. <b>2024 yılı Kurban Bayramı için belirlenen küçükbaş kurban bedeli 4000 TL’dir.</b>
            </p>
          </div>
        </div>
            
        <div className="row">
          <div className="col-12 flex justify-content-center">
            <Image src={photo4} alt="kurban" className=" kurban_image" preview />
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center ">
            <p className="kurban-title">Kurbanımı Nasıl Bağışlayabilirim?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center ">
            <p className="kurban-description">
              Kurbanını İnsan İzi aracılığıyla kestirmek isteyen bağışçılarımız aşağıdaki butona tıklayarak veya banka hesap numaralarımıza EFT - Havale yaparak bağışta bulunabilir. Detaylı bilgi ve aklınıza takılan her türlü soru için ise <b>0850 532 25 40</b> numaralı telefondan bizlere ulaşabilirsiniz.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex justify-content-center">
            <Image src={photo5} alt="kurban" className=" kurban_image" preview />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sss ">
            <p className="kurban-title-sss">SIKÇA SORULAN SORULAR</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-accordion">
            <Accordion multiple activeIndex={[0]} >
              <AccordionTab header="İsmimin okunduğu kurban videomu gönderiyor musunuz?">
                <p>Evet, İnsan İzi kurban çalışmalarının tamamını kayıt altına alır, kesim ve dağıtım görüntülerinden oluşan videonuzu Kurban Bayramı sonrası cep telefonunuza gönderir.</p>
              </AccordionTab>
              <AccordionTab header="İhtiyaç sahibi insanlar nasıl belirleniyor?">
                <p>İnsan İzi’nin kurban çalışması yürüttüğü bölgelerde, yıl içerisinde de insani yardım projeleri devam etmektedir. Çalışmalar sırasında partner kuruluşlarımızın da katkılarıyla bölgelerdeki en ihtiyaç sahibi coğrafyalar belirlenmekte ve faaliyetler daha çok buralardaki sıkıntıların giderilmesi üzerine planlanmaktadır. Kurban etinin dağıtımı yapılacak ihtiyaç sahibi bölgelerde yetimhaneler, okullar medreseler özel olarak hassasiyet gösterdiğimiz noktalardır.</p>
              </AccordionTab>
              <AccordionTab header="Yardım Kuruluşlarına Vekâlet Verilebilir Mi?">
                <p>Evet kurban kesmek için vekaletinİzi verebilirsiniz. Ancak aranan şart, Kuruluşların kesilen kurbanı, İslam’a uygun bir şekilde kesmesi, kestirmesi ve etlerini sİzin adınıza ihtiyaç sahibi muhtaçlara dağıtması gerekmektedir.</p>
              </AccordionTab>
              <AccordionTab header="Kurbanımın Kesildiğini Nasıl Öğrenebilirim?">
                <p>Kesilen kurbanınızı İnsan İzi ilk olarak SMS yolu ile namazınız için bilgilendiriyor, ekiplerimiz kurban operasyonunu tamamlayıp yurda döndüklerinde kesim ve dağıtım videonuzdan oluşan görüntüleri cep telefonunuza ayrıca iletiyor.</p>
              </AccordionTab>
              <AccordionTab header="Yurtdışından Kurban Bağışı Yapmak Mümkün Mü?">
                <p>Evet bağışlarınızı online şekilde yapıp, vekaletinizi bize ulaştırabilirsiniz.</p>
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Kurban;
