import React from "react";

import { Row, Col, Button, } from "reactstrap";

import { InputText } from "primereact/inputtext";
import { setSepet } from "store/common";
import { useDispatch, useSelector } from 'react-redux';
//import Action from "store/redux/actions/Action.js";
import { confirmDialog } from 'primereact/confirmdialog';
import { fetchAddBasket } from "store/store/actions/basket";



const NormalDonation = props => {

  const dispatch = useDispatch();
  const [refCode, setRefCode] = React.useState(null);

  const accept = () => {
    window.document.location = '/basket';
  }

  React.useEffect(()=>{
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if(!sessionStorage.getItem('refCode')) {
      if(params.refCode){
        localStorage.setItem('refCode', params.refCode);
        setRefCode(params.refCode);
      }
      else {
        localStorage.setItem('refCode', "");
        setRefCode(null);
      }
    } 
  }, [])

  return (
    <div className="general-donation" style={{backgroundImage: `linear-gradient(to bottom, rgba(86, 87, 92, 0.1), rgba(21, 21, 26)), url(${process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_DONATION_IMAGE + props.donationTypeID})`}}>
        <div className="general-text">
            <p className="general-title">{props.title}</p>
            <p className="general-description">{props.description}</p>
            <Row>
                <Col className="p-1 align-self-center" xs="6">
                    <InputText className="w-100 px-1 general-input text-center" placeholder="Bağış Miktarı" disabled  value={new Intl.NumberFormat('tr-TR', { currency: "TUR"  }).format(props.amount) + " ₺"}  />
                </Col>
                <Col className="p-1" xs="6">
                    <Button className="w-100 px-1 general-donate-button" onClick={(e)=>{
                        let donation = { DonationTypeID: props.donationTypeID, title: props.title, refCode , description: props.description, type: 2, amount: props.amount, otherCheck: props.isHisse, piece: 1,  name: '', phone: '', mail: '', otherName: '', otherPhone: '', otherMail: '',  otherPhoneError: false, otherMailError: false, otherNameError: false, isVekalet: props.isVekalet, isHisse: props.isHisse, hisseInfo: [{nameSurname: null, phone: null, mail: null,nameError: false, phoneError: false, mailError: false}] };
                        dispatch(fetchAddBasket(donation, props.amount));
                        confirmDialog({
                          message: 'Bağışınız için teşekkür eder, yardımlarınızın devamını dileriz.',
                          header: 'Bağışınız Sepete Eklenmiştir!',
                          icon: 'pi pi-check',
                          acceptLabel: 'Sepete Git',
                          rejectLabel: 'Bağışa Devam Et',
                          accept
                      });
                    }}>Bağış Yap</Button>
                </Col>
            </Row>
        
        </div>
    </div>
  );
}

export default NormalDonation;
