import {FETCH_ADD_BASKET, GET_BASKET_STORAGE, FETCH_BASKET_ACTION_COUNT_ITEM } from '../constans';

export const fetchAddBasket = (donation, defaultPrice) => {
  return async dispatch => {
    dispatch({
      type: FETCH_ADD_BASKET,
      payload: {
        donation
        // defaultPrice:defaultPrice,
        // count:1,
      }
    });
    const oldproduct = localStorage.getItem('sepet') ? localStorage.getItem('sepet') : "[]";
    const arrayproduct =  JSON.parse(oldproduct);  
    arrayproduct.push(donation);
    localStorage.setItem('sepet', JSON.stringify(arrayproduct)); 
  };
}

export function getBasketStorage() {
  return async dispatch => {
    dispatch({
      type: GET_BASKET_STORAGE,
      payload: JSON.parse(localStorage.getItem("sepet"))
    });
  };
}

export function fetchBasketItemActionCount(newPrice, productCount, productId, defaultPrice) {
  return async dispatch => {
    dispatch({
      type: FETCH_BASKET_ACTION_COUNT_ITEM,
      payload: {
        newPrice:newPrice,
        productCount:productCount,
        productId:productId,
      }
    });
  };
}
