/*eslint-disable*/
import React from "react";

function TopBar() {

    return (
            <div className="top-bar d-flex align-items-center text-md-left ">
                <div className="container">
                    <div className="row align-items-center d-flex ">
                        <div className="col top-bar-container">
                                <a href="/hesap_numaralarimiz" style={{color: '#FFF'}} ><p className="top-bar-paragraf">HESAP NUMARALARIMIZ</p></a>
                                <p style={{marginTop: 'auto', marginBottom: 'auto'}}>|</p>
                                <a href="/kurban" style={{color: '#FFF'}} ><p className="top-bar-paragraf">2024 KURBAN</p></a>
                                {/* <a href="https://insanizi.org/ramazan" style={{color: '#FFF'}} ><p className="top-bar-paragraf">2023 RAMAZAN</p></a> */}
                                <p style={{marginTop: 'auto', marginBottom: 'auto'}}>|</p>
                                <a href="https://forms.gle/iNv9qEaGScLe1zwv6" target="_blank" style={{color: '#FFF'}} ><p className="top-bar-paragraf">GÖNÜLLÜ OL</p></a>
                                <p style={{marginTop: 'auto', marginBottom: 'auto'}}>|</p>
                                <a href="/contact" style={{color: '#FFF'}} ><p className="top-bar-paragraf">İLETİŞİM</p></a>
                        </div>
                        
                    </div>
                </div>
            </div>
      );

}

export default TopBar;