import { ADD_PASSWORD, ADD_PHONE, GET_DONATION_LIST, LOGIN_ADD } from '../constans';

export const fetchDonationList = (donation) => {
  return async dispatch => {
    let donationNew = donation.map(r=>{
      if(r.VideoLink && r.VideoLink.indexOf('#') !== -1) {
        let split = r.VideoLink.split('#');
        r['type'] = split[0];
        r['media'] = split[1];
      }
      else {
        if(r.VideoLink) {
          r['type'] = 'video';
        }
        r['media'] = r.VideoLink;
      }
      return r;

    })
    dispatch({
      type: LOGIN_ADD,
      payload: {
        donationNew
      }
    });
    localStorage.setItem('donationList', JSON.stringify(donationNew)); 
  };
}

export const addPhoneNumber = (donation) => {
    return async dispatch => {
      dispatch({
        type: ADD_PHONE,
        payload: {
          donation
        }
      });
      localStorage.setItem('phone', donation); 
    };
}

export const addPassword = (donation) => {
    return async dispatch => {
      dispatch({
        type: ADD_PASSWORD,
        payload: {
          donation
        }
      });
      localStorage.setItem('password', donation); 
    };
}

export function getDonationList() {
  return async dispatch => {
    dispatch({
      type: GET_DONATION_LIST,
      payload: JSON.parse(localStorage.getItem("donationList"))
    });
  };
}
