import React, { useState} from "react";
import {
  Row,
  Col,
  Button,
  Input,
} from "reactstrap";
import { setSepet } from "store/common";
import { useDispatch } from 'react-redux';
//import Action from "store/redux/actions/Action.js";
import { confirmDialog } from 'primereact/confirmdialog';
import { fetchAddBasket } from "store/store/actions/basket";




const GeneralDonation = (props) => {

    const[amount, setAmount] = useState("");
    const [refCode, setRefCode] = React.useState(null);

    const dispatch = useDispatch();

    const accept = () => {
        window.document.location = '/basket';
      }

      React.useEffect(()=>{
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if(!sessionStorage.getItem('refCode')) {
          if(params.refCode){
            localStorage.setItem('refCode', params.refCode);
            setRefCode(params.refCode);
          }
          else {
            localStorage.setItem('refCode', "");
            setRefCode(null);
          }
        } 
      }, [])
    

    return (
    <div className="general-donation" style={{backgroundImage: `linear-gradient(to bottom, rgba(86, 87, 92, 0.1), rgba(21, 21, 26)), url(${process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_DONATION_IMAGE + props.donationTypeID})`}}>
        <div className="general-text">
            <p className="general-title">{props.title}</p>
            <p className="general-description">{props.description}</p>
            <Row>
                {
                    props.buttonNames && props.buttonNames.map(r =>{
                        return <Col className="p-1" xs="4" md="2" >
                        <Button className={amount == r.Amount ? "button-active w-100 px-1" : "button-inactive w-100 px-1" } onClick={()=>{
                            setAmount(r.Amount);
                        }}>{r.ButtonName}</Button>
                    </Col>
                    }) 
                }
                <Col className="p-1 align-self-center" xs="6" md="3">
                    <Input className="w-100 px-1 general-input" placeholder="Miktar..."  value={amount} onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if(re.test(e.target.value) || !e.target.value){
                            setAmount(e.target.value);
                        }

                    }} />
                </Col>
                <Col className="p-1" xs="6" md="3">
                    <Button className="w-100 px-1 general-donate-button" onClick={(e)=>{
                        if(amount) {
                            let donation = { DonationTypeID: props.donationTypeID, title: props.title,refCode, description: props.description, type: 1, amount: amount, otherCheck: props.isHisse, piece: 1, name: '', phone: '', mail: '', otherName: '', otherPhone: '', otherMail: '', otherPhoneError: false, otherMailError: false, otherNameError: false, isVekalet: props.isVekalet, isHisse: props.isHisse, hisseInfo: [{nameSurname: null, phone: null, mail: null, nameError: false, phoneError: false, mailError: false}]};
                            dispatch(fetchAddBasket(donation, props.amount));
                            confirmDialog({
                                message: 'Bağışınız için teşekkür eder, yardımlarınızın devamını dileriz.',
                                header: 'Bağışınız Sepete Eklenmiştir!',
                                icon: 'pi pi-check',
                                acceptLabel: 'Sepete Git',
                                rejectLabel: 'Bağışa Devam Et',
                                accept
                            });
                        } else {
                            confirmDialog({
                                message: 'Bağış Tutarı Giriniz!',
                                header: 'Hata',
                                icon: 'pi pi-check',
                                acceptLabel: 'Tamam',
                                rejectClassName: 'd-none'
                            });

                        }

                    }}>Bağış Yap</Button>
                </Col>
            </Row>
        
        </div>
    </div>
  );
}

export default GeneralDonation
