import TopBar from "../Headers/TopBar.js";
import React from "react";
//import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import {BiDonateHeart} from "react-icons/bi";
import { Menubar } from 'primereact/menubar';


// reactstrap components
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarBrand,
  Navbar,
  Container,
  UncontrolledTooltip,
  Dropdown,
} from "reactstrap";
import logo from '../../assets/img/logo_white.svg';
import { Badge } from 'primereact/badge';
import { useDispatch, useSelector } from "react-redux";
import { getBasketStorage } from "store/store/actions/basket.js";
import { getDonationList } from "store/store/actions/login.js";
import useMediaQuery from "components/useMediaQuery.js";

function IndexNavbar() {
  const basket = useSelector(state => state.basket.basketList);
  const username = useSelector(state => state.login.donationList);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const dispatch = useDispatch();
  const isDesktop = useMediaQuery('(min-width: 960px)');

  React.useEffect(() => {
    if(JSON.parse(localStorage.getItem("sepet")) != null) {
      dispatch(getBasketStorage());
    }
    if(JSON.parse(localStorage.getItem("donationList")) != null) {
      dispatch(getDonationList());
    }
  }, []);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <>
      <header className="fixed-top">
        <TopBar />
      <Navbar className="bg-danger d-flex justify-content-around" expand="lg" color="danger" >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="https://insanizi.org/"
              id="navbar-brand"
            >
              <img src={logo }  height={isDesktop ? 50 : 40} alt="İnsan İzi Derneği"/>
            </NavbarBrand>
            {/* <UncontrolledTooltip target="#navbar-brand">
            İyiliğin izi kalıcı olur.
            </UncontrolledTooltip> */}
          </div>
          <div className="d-flex justify-content-end">
            {
              username.length > 0 ? 
              <Dropdown isOpen={dropdownOpen} toggle={toggle} className="flex">
                <DropdownToggle caret>{username.length > 0 ? username[0].DonorNameSurname.split(' ')[0] : ''}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={()=>{ 
                  window.location.href = "/profil"
                }}><i className="pi pi-fw pi-bars" ></i>Bağışlar</DropdownItem>
                  <DropdownItem onClick={()=>{ 
                  dispatch({type: "REMOVE_USER"});
                  sessionStorage.clear(); 
                  window.location.href = "/giris";
                }}><i className="pi pi-fw pi-external-link" ></i>Çıkış</DropdownItem>
                </DropdownMenu>
              </Dropdown> :
                <Button className='sign-up' style={{margin: 'auto'}} href="/giris" >BAĞIŞÇI GİRİŞİ <FiLogIn size={20}/></Button>

            }
              <Button className='donation' href="/basket">
                <BiDonateHeart size={25}/>   
                <span className="button-donate">BAĞIŞ SEPETİ</span>
                {
                  basket.length ? <Badge value={basket.length} severity="danger" ></Badge> : null
                }
              </Button>
            </div>
          
        </Container>
      </Navbar>
      </header>

    </>
  );
}

export default IndexNavbar;
