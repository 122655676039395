//BASKET
export const FETCH_ADD_BASKET = "FETCH_ADD_BASKET";
export const GET_BASKET_STORAGE = "GET_BASKET_STORAGE";
export const FETCH_BASKET_ACTION_COUNT_ITEM = "FETCH_BASKET_ACTION_COUNT_ITEM";
export const FETCH_BASKET_CLEAR = "FETCH_BASKET_CLEAR";
export const BASKET_ITEM_DELETE = "BASKET_ITEM_DELETE";
export const ACTIVE_SHADOW = "ACTIVE_SHADOW";
export const BASKET_LIST_OPEN = "BASKET_LIST_OPEN";
export const SEARCH_LIST_RESULT_OPEN = "SEARCH_LIST_RESULT_OPEN";
export const BASKET_VALUE_UPDATE = "BASKET_VALUE_UPDATE";
export const BASKET_HISSE_VALUE_UPDATE = "BASKET_HISSE_VALUE_UPDATE";
export const BASKET_HISSE_VALUE_REMOVE = "BASKET_HISSE_VALUE_REMOVE";
export const BASKET_UPDATE = "BASKET_UPDATE";
export const BASKET_REMOVE = "BASKET_REMOVE";

export const LOGIN_ADD = "LOGIN_ADD";
export const ADD_PHONE = "ADD_PHONE";
export const ADD_PASSWORD = "ADD_PASSWORD";
export const GET_DONATION_LIST = "GET_DONATION_LIST";
export const GET_USERNAME = "GET_USERNAME";
export const REMOVE_USER = "REMOVE_USER";






