import {
  FETCH_ADD_BASKET, 
  BASKET_ITEM_DELETE,
  FETCH_BASKET_ACTION_COUNT_ITEM,
  FETCH_BASKET_CLEAR, 
  GET_BASKET_STORAGE,
  ACTIVE_SHADOW,
  BASKET_LIST_OPEN,
  SEARCH_LIST_RESULT_OPEN,
  BASKET_VALUE_UPDATE,
  BASKET_HISSE_VALUE_UPDATE,
  BASKET_HISSE_VALUE_REMOVE,
  BASKET_UPDATE,
  BASKET_REMOVE
} 
from '../constans';
const initialState = {
  basketList:[],
  activeBasketList:false,
  activeShadow:false,
  searchListOpen:false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADD_BASKET:
      if(JSON.parse(localStorage.getItem("sepet") == null)){
        return{
          ...state,
          basketList: [...state.basketList, action.payload] 
        }
      }
      else{
        return{
          ...state,
          basketList: [...JSON.parse(localStorage.getItem("sepet")), action.payload]
        }
      }
    case BASKET_ITEM_DELETE:
      const newBasketList = state.basketList.filter((item, index) =>  index != action.payload );
      if(newBasketList.length == 0) localStorage.removeItem('sepet');
      
      return{
        ...state,
        basketList: newBasketList 
    }
    case BASKET_VALUE_UPDATE:
      let newData = state.basketList.filter((fil, ind) => {
        if (ind === action.payload.index) {
          fil[action.payload.key] = action.payload.value;
        }
        return fil;
      });
      
      return{
        ...state,
        basketList: [...newData] 
    }
    case BASKET_HISSE_VALUE_UPDATE:
      let newData2 = state.basketList.filter((fil, ind) => {
        if (ind === action.payload.index) {
          let newHisseInfo = fil.hisseInfo.filter((e,i)=>{
            if(i === action.payload.hisseIndex){
              e[action.payload.key] = action.payload.value;
            }
          });
          fil.hisseIndex = [...newHisseInfo];

        }
        return fil;
      });
      
      return{
        ...state,
        basketList: [...newData2] 
    }
    case BASKET_HISSE_VALUE_REMOVE:
      let newBasketData = state.basketList.filter((fil, ind) => {
        if (ind === action.payload.index) {
          let newHisse = [...fil.hisseInfo];
          newHisse.pop();
          fil.hisseInfo = [...newHisse];
        }
        return fil;
      });

      return{
        ...state,
        basketList: [...newBasketData] 
    }
    case BASKET_UPDATE:
      return{
        ...state,
        basketList: [...action.payload] 
    }
    case BASKET_REMOVE:
      localStorage.removeItem('sepet');
      return{
        ...state,
        basketList: [] 
    }
    case FETCH_BASKET_ACTION_COUNT_ITEM:
      const defaultBasketList = [...state.basketList]
      defaultBasketList.forEach(_basketList => {
        if(_basketList.product.id == action.payload.productId){
          _basketList.count = action.payload.productCount
          _basketList.product.price = action.payload.newPrice
        } 
      });
      return{
        ...state,
        basketList: [...state.basketList] 
      }

    case FETCH_BASKET_CLEAR:
      return{
        ...state,
        basketList: action.payload
    }

    case GET_BASKET_STORAGE:
      return{
        ...state,
        basketList: action.payload
      }
    case ACTIVE_SHADOW:
      return{
        ...state,
        activeShadow: action.payload
      }
    case BASKET_LIST_OPEN:
      return{
        ...state,
        activeBasketList: action.payload
      }
    case SEARCH_LIST_RESULT_OPEN:
      return{
        ...state,
        searchListOpen: action.payload
    }

    default:
      return state;
  }
}