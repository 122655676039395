import axios from 'axios';
import { getCookie } from 'store/common';


let RestConstants = (function () {
    let baseUrl = process.env.REACT_APP_BASE_URL;

    const HttpMethods = {
        GET: 'GET',
        POST: 'POST',
        PUT: 'PUT',
        DELETE: 'DELETE'
    };


    const ajax = (url, body, method) => {

        return axios({
            method: method || "GET",
            url: baseUrl + url,
            headers: url === '/token' ? 
                {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                } : 
                {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getCookie()
                },
            data: body,
        }).catch(err => {
            const message = err.message;
            const statusCode = err.response ? err.response.status : 500;
            const response = err.response;
            return Promise.reject({message, statusCode, response});
        })
    };
    const ajaxImage = (url, body, method) => {
        return axios({
            method: method || "GET",
            url: baseUrl + url,
            headers: {
                'Content-Type':'multipart/form-data',
                'Authorization': 'Bearer ' + getCookie() 
            },
            data:body
        }).catch(err => {
            const message = err.message;
            const statusCode = err.response ? err.response.status : 500;
            const response = err.response;
            return Promise.reject({message, statusCode, response});
        })
    };


    const jFetch = (url) => {
        return ajax(url);
    };

    const jDelete = (url, body) => {
        return ajax(url, body, HttpMethods.DELETE);
    };

    const jPut = (url, body) => {
        return ajax(url, body, HttpMethods.PUT);
    };

    const jPost = (url, body) => {
        return ajax(url, body, HttpMethods.POST);
    };
    const JImage = (url, body, post) => {
        if(post==="post") return ajaxImage(url, body, HttpMethods.POST);
        else return ajaxImage(url, body)

    };

    return {
        baseUrl: baseUrl,
        jDelete: jDelete,
        jPost: jPost,
        jPut: jPut,
        jFetch: jFetch,
        JImage: JImage,
      
    }
})();

export default RestConstants;
