import React, { useState } from "react";
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import PhoneInput from 'react-phone-input-2'
import Remotes from "Remotes";
import { confirmDialog } from 'primereact/confirmdialog';
import { setCookie } from "store/common";

function Login() {

    const[phoneNumber, setPhoneNumber] = useState();
    const[password, setPassword] = useState();
    const[loadingSMS, setLoadingSMS] = useState(false);
    const[loadingLogin, setLoadingLogin] = useState(false);
    
    const loginBtn = () => {
        if(phoneNumber && password) {
            setLoadingLogin(true);
            
            let response = 'grant_type=password&username='+phoneNumber+'&password='+password;
            Remotes.smsPasswordControl(response).then(r=>{
                if(r.status === 200) {
                    setCookie(r.data.access_token);
                    window.location.href = '/profil';
                }
                setLoadingLogin(false);
                
            }).catch(()=> setLoadingLogin(false) )
        }
        
    }

    const smsPassword = () => {
        if(phoneNumber) {
            setLoadingSMS(true);
            let response = {
                PhoneNumber: phoneNumber
            }
            Remotes.smsPassword(response).then(r=>{
                if(r.status === 200) {
                    setLoadingSMS(false);
                    confirmDialog({
                        message: 'Kod telefonunuza gönderildi.',
                        header: 'Başarılı',
                        icon: 'pi pi-check',
                        acceptLabel: 'Tamam',
                        rejectClassName: 'd-none'
                    });
                }
            }).catch(e=> setLoadingSMS(false))
        } else {
            confirmDialog({
                message: "Telefon Numarası Giriniz!",
                header: 'Hata',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Tamam',
                rejectClassName: 'hidden',
                
              });
        }
    }

    return (
        <section className="login">
            <div className="wrap-login100 p-5 card">
                    <div className="login100-form" >
                        <span className="login100-form-title pb-4">Bağışçı Girişi </span>
                        <div className="flex justify-align-end fluid formgrid grid">
                            <div className="field col-8 md:col-8 lg:col-8 p-1" >
                                <span>
                                    <label style={{fontSize: '12px', color: '#6c757d'}}>Telefon Numarası</label>
                                    <PhoneInput  country={'tr'} countryCodeEditable={false} placeholder="Telefon Numaranız" className='mb-2' value={phoneNumber} onChange={e=> setPhoneNumber(e)} />
                                </span>
                            </div>
                            <div className="field col-4 md:col-4 lg:col-4 p-1 flex align-items-end">
                                <Button  label="Kod Gönder" loading={loadingSMS} className="p-button-sm w-full sendCode mb-2" onClick={smsPassword} />
                            </div>
                        </div>
                        
                        <span className="p-float-label mt-4" >
                            <Password toggleMask feedback={false} className="w-full" value={password} onChange={e=> setPassword(e.target.value)} />
                            <label htmlFor="parola">Kod</label>
                        </span>
                        
                        <label className="login_description">Cep telefon numaranızı girip, daha sonra gelen şifreyi yazarak giriş yapabilirsiniz.</label>
                        <Button className="w-full"  label="Giriş Yap" loading={loadingLogin} onClick={loginBtn} />
                    </div>
                </div>
        </section>
      );
}
export default Login;
