import React from "react";
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import { Divider } from 'primereact/divider';
import { BiCopy } from "react-icons/bi";


function AccountNumbers() {

  const [copyBtnTooltipText, setCopyBtnTooltipText] = React.useState('Kopyala');
  const [copyIbanText, setCopyIbanText] = React.useState('Kopyala');
  const [copyAccountName, setCopyAccountName] = React.useState('Kopyala');

    return (
        <section className="section-account-number" >
            <Container>
              <Row>
                <Col md="6" xs="12">
                  <div className="card p-4">
                    <p className="moneyTitle"> Ziraat Bankası Türk Lirası</p>
                    <p>
                      <span className="font-weight-bold">Hesap Adı: </span> 
                      <span>İNSAN İZİ ULUSLARARASI İNSANİ YARDIM DERNEĞİ</span> 
                      <a className="account-number-copy" id="accountName1"  onClick={()=>{
                          navigator.clipboard.writeText('İNSAN İZİ ULUSLARARASI İNSANİ YARDIM DERNEĞİ'); 
                          setCopyAccountName('Kopyalandı!')
                        }}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#accountName1">{copyAccountName}</UncontrolledTooltip>
                    </p>
                    <Divider />
                    <p>
                      <span className="font-weight-bold">Hesap No: </span> 
                      <span>96821545-5001</span> 
                      <a className="account-number-copy" id="accountNumber1"  onClick={()=>{navigator.clipboard.writeText('96821545-5001'); setCopyBtnTooltipText('Kopyalandı!')}}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#accountNumber1">{copyBtnTooltipText}</UncontrolledTooltip>
                    </p>
                    <p>
                      <span className="font-weight-bold">IBAN: </span> 
                      <span>TR66 0001 0009 1796 8215 4550 01</span>
                      <a className="account-number-copy" id="iban1"  onClick={()=>{navigator.clipboard.writeText('TR660001000917968215455001'); setCopyIbanText('Kopyalandı!')}}>
                        <BiCopy className="ml-2" />
                      </a>
                      <UncontrolledTooltip target="#iban1">
                      {copyIbanText}
                      </UncontrolledTooltip>
                    </p>
                    <Divider />
                    <p>Lütfen açıklama kısmına <b>Ad Soyad ve Cep Telefonu</b> ekleyiniz.</p>
                  </div>
                </Col>
                <Col md="6" xs="12">
                  <div className="card p-4">
                    <p className="moneyTitle">Albaraka Türk Bankası Türk Lirası</p>
                    <p>
                      <span className="font-weight-bold">Hesap Adı: </span> 
                      <span>İNSAN İZİ ULUSLARARASI İNSANİ YARDIM DERNEĞİ</span> 
                      <a className="account-number-copy" id="accountName2"  onClick={()=>{
                          navigator.clipboard.writeText('İNSAN İZİ ULUSLARARASI İNSANİ YARDIM DERNEĞİ'); 
                          setCopyAccountName('Kopyalandı!')
                        }}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#accountName2">{copyAccountName}</UncontrolledTooltip>
                    </p>
                    <Divider />
                    <p>
                      <span className="font-weight-bold">Hesap No: </span> 
                      <span>9278456-1</span> 
                      <a className="account-number-copy" id="accountNumber2"  onClick={()=>{navigator.clipboard.writeText('9278456-1'); setCopyBtnTooltipText('Kopyalandı!')}}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#accountNumber2">
                      {copyBtnTooltipText}
                      </UncontrolledTooltip>
                    </p>
                    <p>
                      <span className="font-weight-bold">IBAN: </span> 
                      <span>TR71 0020 3000 0927 8456 0000 01</span>
                      <a className="account-number-copy" id="iban2"  onClick={()=>{navigator.clipboard.writeText('TR710020300009278456000001'); setCopyIbanText('Kopyalandı!')}}>
                        <BiCopy className="ml-2" />
                      </a>
                      <UncontrolledTooltip target="#iban2">
                      {copyIbanText}
                      </UncontrolledTooltip>
                    </p>
                    <Divider />
                    <p>Lütfen açıklama kısmına <b>Ad Soyad ve Cep Telefonu</b> ekleyiniz.</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6" xs="12">
                  <div className="card p-4">
                    <p className="moneyTitle"> Ziraat Bankası Dolar</p>
                    <p>
                      <span className="font-weight-bold">Hesap Adı: </span> 
                      <span>İNSAN İZİ ULUSLARARASI İNSANİ YARDIM DERNEĞİ</span> 
                      <a className="account-number-copy" id="accountName3"  onClick={()=>{
                          navigator.clipboard.writeText('İNSAN İZİ ULUSLARARASI İNSANİ YARDIM DERNEĞİ'); 
                          setCopyAccountName('Kopyalandı!')
                        }}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#accountName3">{copyAccountName}</UncontrolledTooltip>
                    </p>
                    <span style={{fontSize: '1.2em'}}><span className="font-weight-bold">Swift: </span> TCZBTR2A</span>
                    <a className="account-number-copy" id="swift3"  onClick={()=>{navigator.clipboard.writeText('TCZBTR2A'); setCopyBtnTooltipText('Kopyalandı!')}}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#swift3">
                      {copyBtnTooltipText}
                      </UncontrolledTooltip>
                    <Divider />
                    <p>
                      <span className="font-weight-bold">Hesap No: </span> 
                      <span>96821545-5010</span> 
                      <a className="account-number-copy" id="accountNumber3"  onClick={()=>{navigator.clipboard.writeText('96821545-5010'); setCopyBtnTooltipText('Kopyalandı!')}}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#accountNumber3">
                      {copyBtnTooltipText}
                      </UncontrolledTooltip>
                    </p>
                    <p>
                      <span className="font-weight-bold">IBAN: </span> 
                      <span>TR76 0001 0004 0396 8215 4550 10</span>
                      <a className="account-number-copy" id="iban3"  onClick={()=>{navigator.clipboard.writeText('TR760001000403968215455010'); setCopyIbanText('Kopyalandı!')}}>
                        <BiCopy className="ml-2" />
                      </a>
                      <UncontrolledTooltip target="#iban3">
                      {copyIbanText}
                      </UncontrolledTooltip>
                    </p>
                    <Divider />
                    <p>Lütfen açıklama kısmına <b>Ad Soyad ve Cep Telefonu</b> ekleyiniz.</p>
                  </div>
                </Col>
                <Col md="6" xs="12">
                  <div className="card p-4">
                    <p className="moneyTitle">Albaraka Türk Bankası Dolar</p>
                    <p>
                      <span className="font-weight-bold">Hesap Adı: </span> 
                      <span>İNSAN İZİ ULUSLARARASI İNSANİ YARDIM DERNEĞİ</span> 
                      <a className="account-number-copy" id="accountName4"  onClick={()=>{
                          navigator.clipboard.writeText('İNSAN İZİ ULUSLARARASI İNSANİ YARDIM DERNEĞİ'); 
                          setCopyAccountName('Kopyalandı!')
                        }}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#accountName4">{copyAccountName}</UncontrolledTooltip>
                    </p>
                    <span style={{fontSize: '1.2em'}}><span className="font-weight-bold">Swift: </span> BTFHTRIS</span>
                    <a className="account-number-copy" id="swift4"  onClick={()=>{navigator.clipboard.writeText('BTFHTRIS'); setCopyBtnTooltipText('Kopyalandı!')}}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#swift4">
                      {copyBtnTooltipText}
                      </UncontrolledTooltip>
                    <Divider />
                    <p>
                      <span className="font-weight-bold">Hesap No: </span> 
                      <span>9278456-2</span> 
                      <a className="account-number-copy" id="accountNumber4"  onClick={()=>{navigator.clipboard.writeText('9278456-2'); setCopyBtnTooltipText('Kopyalandı!')}}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#accountNumber4">
                      {copyBtnTooltipText}
                      </UncontrolledTooltip>
                    </p>
                    <p>
                      <span className="font-weight-bold">IBAN: </span> 
                      <span>TR44 0020 3000 0927 8456 0000 02</span>
                      <a className="account-number-copy" id="iban4"  onClick={()=>{navigator.clipboard.writeText('TR440020300009278456000002'); setCopyIbanText('Kopyalandı!')}}>
                        <BiCopy className="ml-2" />
                      </a>
                      <UncontrolledTooltip target="#iban4">
                      {copyIbanText}
                      </UncontrolledTooltip>
                    </p>
                    <Divider />
                    <p>Lütfen açıklama kısmına <b>Ad Soyad ve Cep Telefonu</b> ekleyiniz.</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6" xs="12">
                  <div className="card p-4">
                    <p className="moneyTitle"> Ziraat Bankası Euro</p>
                    <p>
                      <span className="font-weight-bold">Hesap Adı: </span> 
                      <span>İNSAN İZİ ULUSLARARASI İNSANİ YARDIM DERNEĞİ</span> 
                      <a className="account-number-copy" id="accountName5"  onClick={()=>{
                          navigator.clipboard.writeText('İNSAN İZİ ULUSLARARASI İNSANİ YARDIM DERNEĞİ'); 
                          setCopyAccountName('Kopyalandı!')
                        }}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#accountName5">{copyAccountName}</UncontrolledTooltip>
                    </p>
                    <span style={{fontSize: '1.2em'}}><span className="font-weight-bold">Swift: </span> TCZBTR2A</span>
                    <a className="account-number-copy" id="swift5"  onClick={()=>{navigator.clipboard.writeText('TCZBTR2A'); setCopyBtnTooltipText('Kopyalandı!')}}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#swift5">
                      {copyBtnTooltipText}
                      </UncontrolledTooltip>
                    <Divider />
                    <p>
                      <span className="font-weight-bold">Hesap No: </span> 
                      <span>96821545-5009</span> 
                      <a className="account-number-copy" id="accountNumber5"  onClick={()=>{navigator.clipboard.writeText('96821545-5009'); setCopyBtnTooltipText('Kopyalandı!')}}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#accountNumber5">
                      {copyBtnTooltipText}
                      </UncontrolledTooltip>
                    </p>
                    <p>
                      <span className="font-weight-bold">IBAN: </span> 
                      <span>TR06 0001 0004 0396 8215 4550 09</span>
                      <a className="account-number-copy" id="iban5"  onClick={()=>{navigator.clipboard.writeText('TR060001000403968215455009'); setCopyIbanText('Kopyalandı!')}}>
                        <BiCopy className="ml-2" />
                      </a>
                      <UncontrolledTooltip target="#iban5">
                      {copyIbanText}
                      </UncontrolledTooltip>
                    </p>
                    <Divider />
                    <p>Lütfen açıklama kısmına <b>Ad Soyad ve Cep Telefonu</b> ekleyiniz.</p>
                  </div>
                </Col>
                <Col md="6" xs="12">
                  <div className="card p-4">
                    <p className="moneyTitle">Albaraka Türk Bankası Euro</p>
                    <p>
                      <span className="font-weight-bold">Hesap Adı: </span> 
                      <span>İNSAN İZİ ULUSLARARASI İNSANİ YARDIM DERNEĞİ</span> 
                      <a className="account-number-copy" id="accountName6"  onClick={()=>{
                          navigator.clipboard.writeText('İNSAN İZİ ULUSLARARASI İNSANİ YARDIM DERNEĞİ'); 
                          setCopyAccountName('Kopyalandı!')
                        }}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#accountName6">{copyAccountName}</UncontrolledTooltip>
                    </p>
                    <span style={{fontSize: '1.2em'}}><span className="font-weight-bold">Swift: </span> BTFHTRIS</span>
                    <a className="account-number-copy" id="swift6"  onClick={()=>{navigator.clipboard.writeText('BTFHTRIS'); setCopyBtnTooltipText('Kopyalandı!')}}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#swift6">
                      {copyBtnTooltipText}
                      </UncontrolledTooltip>
                    <Divider />
                    <p>
                      <span className="font-weight-bold">Hesap No: </span> 
                      <span>9278456-3</span> 
                      <a className="account-number-copy" id="accountNumber6"  onClick={()=>{navigator.clipboard.writeText('9278456-3'); setCopyBtnTooltipText('Kopyalandı!')}}>
                        <BiCopy className="ml-2"  />
                      </a>
                      <UncontrolledTooltip target="#accountNumber6">
                      {copyBtnTooltipText}
                      </UncontrolledTooltip>
                    </p>
                    <p>
                      <span className="font-weight-bold">IBAN: </span> 
                      <span>TR17 0020 3000 0927 8456 0000 03</span>
                      <a className="account-number-copy" id="iban6"  onClick={()=>{navigator.clipboard.writeText('TR170020300009278456000003'); setCopyIbanText('Kopyalandı!')}}>
                        <BiCopy className="ml-2" />
                      </a>
                      <UncontrolledTooltip target="#iban6">
                      {copyIbanText}
                      </UncontrolledTooltip>
                    </p>
                    <Divider />
                    <p>Lütfen açıklama kısmına <b>Ad Soyad ve Cep Telefonu</b> ekleyiniz.</p>
                  </div>
                </Col>
              </Row>
            </Container>
          
        </section>
      );
}
export default AccountNumbers;
