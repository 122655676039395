import {
  useEffect,
  useMemo,
  useRef
} from "react";
import css from "../../assets/css/styles.module.css";
import { CheckSVG, CloseSVG, SendSVG, WhatsappSVG } from "./Icons";

import { useState } from "react";
import lightBG from "../../assets/img/bg-chat-tile-dark.png";
import dummyAvatar from "../../assets/img/logoRenkli.jpg";

export function FloatingWhatsApp({
  onClick,
  onSubmit,
  onClose,
  onNotification,
  onLoopDone,

  phoneNumber = "+905308198100",
  accountName = "İnsan İzi",
  avatar = dummyAvatar,
  statusMessage = "Çevrimiçi",
  chatMessage = "Merhabalar.\nSize nasıl yardımcı olabilirim?",
  placeholder = "Mesajınızı buraya yazabilirsiniz...",

  allowClickAway = false,
  allowEsc = false,

  notification = true,
  notificationDelay = 60,
  notificationLoop = 0,
  notificationStyle,
  notificationClassName = "floating-whatsapp-notification",

  buttonStyle,
  buttonClassName = "floating-whatsapp-button",

  chatboxHeight = 320,
  chatboxStyle,
  chatboxClassName = "floating-whatsapp-chatbox",

  style,
  className = "floating-whatsapp"
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isDelay, setIsDelay] = useState(false);
  const [isNotification, setIsNotification] = useState(false);


  const timeNow = useMemo(
    () =>
      new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
    []
  );

  const inputRef = useRef(null);
  const loops = useRef(0);
  const notificationInterval = useRef(0);

  const handleNotification = () => {
    if (!notification) return;

    if (onNotification) onNotification();
    if (notificationLoop > 0) {
      loops.current += 1;


      if (loops.current === notificationLoop) {
        clearInterval(notificationInterval.current);
        if (onLoopDone) onLoopDone();
      }
    }
  };

  useEffect(() => {
    const isWhatsapp = localStorage.getItem("isWhatsapp");
    if(!isWhatsapp) {
      setTimeout(() => {
        setIsNotification(true);
      }, 3000)
    }
  }, [])

  // useEffect(() => {
  //   const delayInSecond = notificationDelay * 1000;
  //   if (delayInSecond < 10)
  //     return console.error(
  //       "notificationDelay prop value must be at least 10 seconds."
  //     );

  //   notificationInterval.current = window.setInterval(
  //     handleNotification,
  //     delayInSecond
  //   );

  //   return () => clearInterval(notificationInterval.current);
  // }, [handleNotification, notificationDelay]);

  const handleOpen = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
    setIsNotification(false);
    localStorage.setItem("isWhatsapp", true);
    if (onClick) onClick(event);
  };

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!inputRef.current?.value) return;

    handleClose();

    window.open(
      `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${inputRef.current.value}`
    );
    if (onSubmit) onSubmit(event, inputRef.current.value);
    inputRef.current.value = "";
  };

  // useEffect(() => {
  //   const onClickOutside = () => {
  //     if (!allowClickAway || !isOpen) return;

  //     handleClose();
  //   };
  //   document.addEventListener("click", onClickOutside, false);

  //   return () => document.removeEventListener("click", onClickOutside);
  // }, [allowClickAway, isOpen, handleClose]);

  // useEffect(() => {
  //   const onEscKey = (event) => {
  //     if (!allowEsc || !isOpen) return;

  //     if (event.key === "Escape") handleClose();
  //   };

  //   document.addEventListener("keydown", onEscKey, false);

  //   return () => document.removeEventListener("keydown", onEscKey);
  // }, [allowEsc, isOpen, handleClose]);

  return (
    <div
      className={`${css.floatingWhatsapp} ${className}`}
      style={style}
    >
      <div
        className={`${css.whatsappButton} ${buttonClassName}`}
        onClick={handleOpen}
        style={buttonStyle}
        aria-hidden="true"
      >
        <WhatsappSVG />
        {isNotification && (
          <span
            className={`${css.notificationIndicator} ${notificationClassName}`}
            style={notificationStyle}
          >
            1
          </span>
        )}
      </div>

      <div
        className={`${css.whatsappChatBox} ${isOpen ? css.open : css.close
          } ${chatboxClassName}`}
        onClick={(event) => event.stopPropagation()}
        aria-hidden="true"
        style={{ height: isOpen ? chatboxHeight : 0, ...chatboxStyle }}
      >
        <header className={css.chatHeader}>
          <div className={css.avatar}>
            <img src={avatar} width="60" height="60" alt="whatsapp-avatar" />
          </div>
          <div className={css.status}>
            <span className={css.statusTitle}>{accountName}</span>
            <span className={css.statusSubtitle}>{statusMessage}</span>
          </div>
          <div className={css.close} onClick={handleClose} aria-hidden="true">
            <CloseSVG />
          </div>
        </header>

        <div
          className={css.chatBody}
          style={{ backgroundImage: `url(${lightBG})` }}
        >
          {isDelay ? (
            <div className={css.chatBubble}>
              <div className={css.typing}>
                <div className={css.dot} />
                <div className={css.dot} />
                <div className={css.dot} />
              </div>
            </div>
          ) : (
            <div className={css.message}>
              <span className={css.triangle} />
              <span className={css.accountName}>{accountName}</span>
              <p className={css.messageBody}>{chatMessage}</p>
              <span className={css.messageTime}>
                {timeNow}
                <span style={{ marginLeft: 5 }}>
                  <CheckSVG />
                </span>
              </span>
            </div>
          )}
        </div>

        <footer className={css.chatFooter}>
          <form onSubmit={handleSubmit}>
            <input
              className={css.input}
              placeholder={placeholder}
              ref={inputRef}
              dir="auto"
            />
            <button type="submit" className={css.buttonSend}>
              <SendSVG />
            </button>
          </form>
        </footer>
      </div>
    </div>
  );
}
