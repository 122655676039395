import React, { useState, useEffect } from "react";
import { Button } from 'primereact/button';
import Remotes from "Remotes";
import { useDispatch, useSelector } from "react-redux";
import { fetchDonationList } from "store/store/actions/login";
import { TabView, TabPanel } from 'primereact/tabview';
import { Row, Col, Label} from "reactstrap";
import {   BiVideo} from "react-icons/bi";
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';

function Profile() {

    const [activeIndex, setActiveIndex] = useState(0);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState();
    const [donationList, setDonationList] = useState([]);

    const dispatch = useDispatch();
    useEffect(() => {
        Remotes.getProfile().then(r=>{
            if(r.status === 200) {
                setDonationList(r.data);
                dispatch(fetchDonationList(r.data));
            }
        })
      }, []);

    const onHide = () => {
        setDisplayDialog(false);
    }

    const watchVideo = (videoLink) => {
        setSelectedVideo(videoLink);
        setDisplayDialog(true);
    }

    return (
        <section className="profile" >
            <div className="container">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Bağışlar">
                        {
                            donationList && donationList.map((item, index) =>{
                                return <><Row key={"row" + index} className="basket-row m-0" >
                                        <Col xs="12" md="2">
                                            <img className="m-auto" src={process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_DONATION_IMAGE + item.DonationTypeInfo.ID} alt={item.title} />
                                        </Col>
                                        <Col xs="12" md="2" className="m-auto text-center" >
                                            <Label className="basket-row-title" >{item.DonationTypeInfo.DonationTitle}</Label>
                                        </Col>
                                        <Divider className="basket-divider" layout="vertical" />
                                        <Col xs="12" md="3" className="m-auto">
                                            <div className="d-flex flex-row">
                                            <div className="p-2 basket-amount m-auto text-center">
                                                <Label for="amount" className="font-weight-bold" >Bağış Bedeli</Label>
                                                <Label className="w-100" id="amount" >{item.Amount / item.DonotionQuantity + " ₺" }</Label>
                                            </div>
                                            {
                                                item.DonationTypeInfo.DonationKindID !== 1 ?
                                                <div className="p-2 basket-count text-center">
                                                <Label for="count" className="font-weight-bold">Adet</Label>
                                                <Label className="w-100"   >{item.DonotionQuantity}</Label>
                                                </div> 
                                                : null
                                            }
                                            </div>
                                        </Col>
                                        <Divider className="basket-divider" layout="vertical" />
                                        <Col xs="12" md="1" className="m-auto text-center" >
                                            <Label className="basket-row-amount">{new Intl.NumberFormat('tr-TR', { currency: "TUR"  }).format(item.Amount) + " ₺"}</Label>
                                        </Col>
                                        <Col xs="12" md="1" className="m-auto text-center" >
                                            <Label className="basket-row-amount">{new Date(item.CreatedDate).toLocaleString()}</Label>
                                        </Col>
                                        <Col xs="12" md="1" className="m-auto text-center" >
                                        {
                                            item.VideoLink ?
                                                <Button onClick={()=>watchVideo(item)} ><BiVideo /></Button>
                                             : null
                                        }
                                        </Col>
                                    </Row><hr/>
                                        
                                </>
                            })
                        }
                    </TabPanel>
                    
                </TabView>
            </div>

            <Dialog header="Video İzle" visible={displayDialog} onHide={onHide} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}} >
                <div className="row">
                    <div className="col-12">
                        {   
                            selectedVideo && selectedVideo.type.indexOf('video') !== -1 ?
                                <video width='100%' controls src={process.env.REACT_APP_BASE_URL + '/' + (selectedVideo.media ?? null)} />
                                : selectedVideo && selectedVideo.type.indexOf('image') !== -1 ? 
                                    <img alt='Bagiş Resim' src={process.env.REACT_APP_BASE_URL + '/' + (selectedVideo.media ?? null)} width='100%' />
                            : <p>Video veya Fotoğraf Yüklenmemiştir</p>

                        }
                        {/* <video src={process.env.REACT_APP_BASE_URL + '/'+ selectedVideo}   width='100%' controls/> */}
                        
                    </div>
                </div>
            </Dialog>
           
        </section>
      );
}
export default Profile;
