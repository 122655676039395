import React, { useEffect, useState } from "react";
import { ProgressSpinner } from 'primereact/progressspinner';

const Paytr = (props) =>{

  const [loading, setLoading] = useState(false);
 

  useEffect(()=> {
    setLoading(true);
    window.addEventListener(
      "message",
      (ev) => {
        if(typeof ev.data === 'string' && ev.data?.includes('##')) {
          let split = ev.data.split('##');
          if(split[0] == '0') {
            window.document.location = '/basket/pay=success&message='+split[1];
          } else {
            window.document.location = '/basket/pay=fail&message='+split[1];
          }
        }
      }
    );
  }, [])

  const frameOnLoad = ()=> {
    setLoading(false);
  }

    return (
      <>
        {props.src ? 
          <iframe
            src={props.src}
            className="iframePayTr"
            frameBorder="0"
            scrolling="yes"
            onLoad={frameOnLoad}
            style={loading ? {display: 'none'} : null}
          />
         : null
         }
         {
          loading ?
          <div style={{margin: "auto", width: 100, height: 100}} className="d-flex flex-column align-items-center">
            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/>
            <p className="text-center" style={{width: 150}}>Lütfen Bekleyiniz</p>
          </div> : null
         }
        
      </>
    );
}

export default Paytr;
