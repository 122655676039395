import React, { useState } from "react";
import { Card, CardBody,  Container, Row, Col, Label} from "reactstrap";
import { BiX, BiLira, BiCheckCircle, BiMinusCircle, BiTrash} from "react-icons/bi";
import { setBasketPiece } from "store/common";

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import Paytr from "components/payTr";
import { Checkbox } from 'primereact/checkbox';
import { Steps } from 'primereact/steps';
import PhoneInput from 'react-phone-input-2'

import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';
import { setBasketAmount } from "store/common";
import { useLocation, useParams } from "react-router-dom";
import Remotes from "Remotes";
import { useDispatch, useSelector } from "react-redux";
import { getBasketStorage } from "store/store/actions/basket";
import { BASKET_UPDATE } from "store/store/constans";
import { fetchAddBasket } from "store/store/actions/basket";

let basketList;

function useQuery() {
  const { search } = useLocation();
  let a =React.useMemo(() => new URLSearchParams(search), [search]);
  return a;
}
function Basket() {
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [nameSurname, setNameSurname] = React.useState();
    const [phone, setPhone] = React.useState();
    const [mail, setMail] = React.useState();
    const [isVekaletShow, setIsVekaletShow] = React.useState(false);
    const [isVekaletAccept, setIsVekaletAccept] = React.useState(false);

    const [esnekPosSRC, setEsnekPosSRC] = React.useState('');

    const [errorMessage, setErrorMessage] = React.useState();

    const toast = React.useRef(null);

    const [phoneError, setPhoneError] = React.useState(false);
    const [mailError, setMailError] = React.useState(false);
    const [nameSurnameError, setNameSurnameError] = React.useState(false);

    const isPhone = /^[0-9\b]+$/;
    const isMail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const { pay }  = useParams();
    const dispatch = useDispatch();

    const[paymentStatus, setPaymentStatus] = React.useState();
    const [loading, setLoading] = useState(false);

    let query = useQuery();

    const [order, setOrder] = useState({
      merchant_oid: "516sdsdf44",
      basket: [["bagis","1","1"]],
      payment_amount: "100"
    });

    const step=[
      { label: "Bağış Özeti" },
      { label: "Ödeme Bilgileri" },
      { label: "Bağış Sonu" }
    ];

    basketList = useSelector(state => state.basket.basketList)

    if(basketList && basketList.length != 0) {
      localStorage.setItem("sepet", JSON.stringify(basketList))
    }


    React.useEffect(() => {
      if(JSON.parse(localStorage.getItem("sepet")) != null) {
        dispatch(getBasketStorage());
      }

      if(pay) {
        setSelectedTab(2);
        let split = pay.split('&');
        let paySplit = split[0].split('=');
        setPaymentStatus(paySplit[1]);
        if(paySplit[1] == 'success') {
          dispatch({type: "BASKET_REMOVE"})
        } else {
          let messageSplit = split[1].split('=');
          setErrorMessage(messageSplit[1]);
        }
      } 
      else if(query.get('title')) {
        dispatch({type: "BASKET_REMOVE"})
        query.getAll('title').forEach((item2, ind) => {
          let localSt  = JSON.parse(localStorage.getItem("sepet"));
          let control = false;
          let newData = localSt?.map(item=> {
            if(item.title == query.getAll('title')[ind] && item.isHisse === true){
              item.piece = item.piece + 1;
              item.hisseInfo.push({nameSurname: null, phone: null, mail: null,nameError: false, phoneError: false, mailError: false})
              control = true;
              setIsVekaletShow(true);
            }
            return item;
          })
          if(control){
            localStorage.setItem('sepet', JSON.stringify(newData) )
          }
          else {
            let donation = { 
              DonationTypeID: parseInt(query.getAll('DonationTypeID')[ind]), 
              title: query.getAll('title')[ind], 
              refCode: null , 
              description: null, 
              type: parseInt(query.getAll('type')[ind]), 
              amount: parseFloat(query.getAll('amount')[ind]), 
              otherCheck: query.getAll('isHisse')[ind] === 'true', 
              piece: 1,  
              name: '', 
              phone: '', 
              mail: '', 
              otherName: '', 
              otherPhone: '', 
              otherMail: '',  
              otherPhoneError: false, 
              otherMailError: false, 
              otherNameError: false, 
              isVekalet: query.getAll('isVekalet')[ind] === 'true', 
              isHisse: query.getAll('isHisse')[ind] === 'true', 
              hisseInfo: [{nameSurname: null, phone: null, mail: null,nameError: false, phoneError: false, mailError: false}] 
            };
            dispatch(fetchAddBasket(donation, donation.amount));
          }
        })
        dispatch(getBasketStorage());
      }
    }, []);

    const confirmDelete = (event, index) => {
      confirmPopup({
          target: event.currentTarget,
          message: 'Bağışı kaldırmak istediğinizden emin misiniz?',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          acceptLabel: 'Evet',
          rejectLabel: 'Hayır',
          accept: () => {accept(index)}
      });
    };

    const accept = (index) => {
      dispatch({ type: 'BASKET_ITEM_DELETE', payload: index });
      setIsVekaletShow(false);
    };

  const setValue = (key, value, index) => {
    dispatch({ type: 'BASKET_VALUE_UPDATE', payload: {key, value, index} })
  }

  const setHisseValue = (key, value, index, hisseIndex) => {
    dispatch({ type: 'BASKET_HISSE_VALUE_UPDATE', payload: {key, value, index, hisseIndex} })
  }

  const setHisseRemove = (index) => {
    dispatch({ type: 'BASKET_HISSE_VALUE_REMOVE', payload: {index} })
  }

  const errorControl = () => {
    let error = true;
    if(!isVekaletShow || (isVekaletShow && isVekaletAccept)) {
      if(!isPhone.test(phone)){
        setPhoneError(true);
        error = false;
      } else {
        setPhoneError(false);
      }
      if(!isMail.test(mail)){
        setMailError(true);
        error = false;
      } else {
        setMailError(false);
      }
      let nameSplit = nameSurname.split(' ');
      if(!nameSurname || !nameSplit[0] || !nameSplit[1]) {
        setNameSurnameError(true);
        error = false;
      } else {
        setNameSurnameError(false);
      }
      let newData = basketList.filter((item) => {
        if(item.isHisse){
          item.hisseInfo.forEach(e=> {
            if(!isPhone.test(e.phone)){
              e.phoneError = true;
              error = false;
            }
            if(!isMail.test(e.mail)){
              e.mailError = true;
              error = false;
            }
            if(!e.nameSurname){
              e.nameError = true;
              error = false;
            }
          })
        }
        else if(item.otherCheck) {
          if(!isPhone.test(item.otherPhone)){
            item.otherPhoneError = true;
            error = false;
          }
          if(!isMail.test(item.otherMail)){
            item.otherMailError = true;
            error = false;
          }
          if(!item.otherName){
            item.otherNameError = true;
            error = false;
          }
        }
        if(item.amount <= 0) {
          error = false;
        }
        return item;
      })
      dispatch({type: BASKET_UPDATE, payload: newData })
     
      if(!error) {
        toast.current.show({severity:'error', summary: 'Hata', detail:'Zorunlu alanların formatları uygun değil', life: 3000});
      }
    } 
    else {
      error = false;
      toast.current.show({severity:'error', summary: 'Hata', detail:'Vekaleti kabul ediniz', life: 3000});

    }
    return error;
  }

    const nextStep = () =>{
      if(errorControl()) {
        setLoading(true);
        let arrayData = [];
        basketList.forEach((r) => {
          if(r.isHisse){
            r.hisseInfo.forEach(hisse=>{
              arrayData.push({
                DonationTypeID: r.DonationTypeID,
                DonorNameSurname: nameSurname,
                TotalAmount: r.type === 1 ? r.amount : null,
                DonorPhoneNumber: phone,
                DonorEmail: mail,
                DonationQuantity: 1,
                IsOtherDonation: r.otherCheck,
                RefCode: r.refCode ?? null,
                OtherNameSurname: hisse.nameSurname,
                OtherNumberPhone: hisse.phone,
                OtherEmail: hisse.mail
              })
            })
          } else{
            arrayData.push({
              DonationTypeID: r.DonationTypeID,
              DonorNameSurname: nameSurname,
              TotalAmount: r.type === 1 ? r.amount : null,
              DonorPhoneNumber: phone,
              DonorEmail: mail,
              DonationQuantity: r.piece,
              IsOtherDonation: r.otherCheck,
              RefCode: r.refCode ?? null,
              OtherNameSurname: r.otherCheck ? r.otherName : null,
              OtherNumberPhone: r.otherCheck ? r.otherPhone : null,
              OtherEmail: r.otherCheck ? r.otherMail : null
            })
          }
        })

        Remotes.saveDonation(arrayData).then(t=>{
          if(t.status === 200) {
            setEsnekPosSRC(t.data);
            setSelectedTab(selectedTab+1)
            window.scrollTo(0, 250);
            setLoading(false);
          }
        }).catch(() => {
          setLoading(false);
        })
      }
    }


    return (
        <section className="basket" >
          <div className="donation-search-area">
            <div className="donation-image" />
            {/* <div className="donation-text">
              <p className="donation-title">"İyiliğin izi kalıcı olur"</p>
              <p className="donation-desc">Desteklerinizle iyiliği büyütüyor, ihtiyaç sahibi coğrafyalarda iz bırakıyoruz!</p>
            </div> */}
          </div>
          <div className="basket-tabs">
            <Card>
              <CardBody>
              <Toast ref={toast} position="top-right" />
                {
                  basketList && basketList.length === 0 && !paymentStatus ?
                  <Row>
                    <Col> 
                      <div style={{height: '100vh'}}>
                        <div className="d-flex flex-column mt-5">
                          <h2 className="m-auto pb-4" >Sepet Boş!</h2>
                          <Button className="m-auto" onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/';
                          }}>Bağış Sayfasına Dön</Button>
                        </div>
                      </div>
                    </Col>
                  </Row> :
                  <>
                  <Steps className="basket_steps" model={step} activeIndex={selectedTab} onSelect={(e) => setSelectedTab(e.index)} />
                  {
                    selectedTab === 0 ?
                      <Container>
                        {
                          basketList && basketList.length === 0 ?
                          <Row >
                            <Col>
                              <div className="d-flex flex-column">
                                <h2 className="m-auto pb-4" >Sepet Boş!</h2>
                                <Button className="m-auto">Bağış Sayfasına Dön</Button>
                              </div>
                            </Col>
                          </Row> : 
                          <>
                        <Row >
                          <Col xs="12"  >
                            <p className="basket-title p-0 m-0">Bağışçı Bilgisi</p>
                          </Col>
                        </Row>
                        <Row >
                          <Col xs="12" md="4"  className="pt-4 ">
                            <span className="p-float-label">
                              <InputText id="name" className={nameSurnameError ? "w-100 p-invalid" : "w-100"} value={nameSurname} 
                                onChange={e=>{setNameSurname(e.target.value)}} onBlur={e=>{if(e.target.value.length > 0) { setNameSurnameError(false) }}} />
                              <label htmlFor="name">Ad Soyad</label>
                            </span>
                            {
                              nameSurnameError ?  <small id="username-help" className="p-error">Ad Soyad alanı zorunludur</small> : null
                            }
                          </Col>
                          <Col xs="12" md="4"  className="pt-4">
                            <span className="p-float-label">
                              <PhoneInput country={'tr'} id="tel" countryCodeEditable={false} className={phoneError ? "w-100 p-invalid-tel" :"w-100"} value={phone} 
                                onChange={e=>{setPhone(e)}} onBlur={e=>{if(isPhone.test(phone)) { setPhoneError(false) }}} />
                              <label className="phoneLabel" htmlFor="tel">Telefon</label>
                            </span>
                            {
                              phoneError ?  <small id="username-help" className="p-error">Telefon formatı yanlış</small> : null
                            }
                            
                          </Col>
                          <Col xs="12" md="4"  className="pt-4">
                            <span className="p-float-label w-100">
                              <InputText id="email" className={mailError ? "w-100 p-invalid" : "w-100"} value={mail} 
                                onChange={e=>{setMail(e.target.value.trim())}} onBlur={e=>{if(isMail.test(e.target.value.trim())) { setMailError(false) }}}  />
                              <label htmlFor="email">E-Posta Adresi</label>
                            </span>
                            {
                              mailError ? <small id="username-help" className="p-error">E-Posta formatı yanlış</small> : null
                            }
                          </Col>
                        </Row>
                        <hr/>
                        {
                          basketList && basketList.map((item, index) => {
                            if(item.isVekalet && isVekaletShow === false){
                              setIsVekaletShow(true);
                            }
                            return (<><Row key={"row" + index} className="basket-row" >
                              <Col xs="12" md="2">
                                <img className="m-auto" src={process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_DONATION_IMAGE + item.DonationTypeID} alt={item.title} />
                              </Col>
                              <Col xs="12" md="2" className="m-auto text-center" >
                                <Label className="basket-row-title" >{item.title}</Label>
                              </Col>
                              <Divider className="basket-divider" layout="vertical" />
                              <Col xs="12" md="3" className="m-auto">
                                <div className="d-flex flex-row">
                                  <div className="p-2 basket-amount m-auto text-center">
                                    <Label for="amount" className="font-weight-bold" >Bağış Bedeli</Label>
                                    <span className="p-input-icon-right donation-input-group">
                                        <BiLira fontSize={22}/>
                                        <InputText className="w-100" id="amount" disabled={item.type !== 1} value={item.amount} onChange={e=> {
                                          if(Number(e.target.value) > 0) {
                                            const re = /^[0-9\b]+$/;
                                            if(re.test(e.target.value)) {
                                              setValue('amount',Number(e.target.value), index); 
                                              setBasketAmount(index, Number(e.target.value));
                                            }
                                          } else {
                                            setValue('amount',0, index);
                                            setBasketAmount(index, 0);
                                          }
                                          }} />
                                    </span>
                                  </div>
                                  {
                                    item.type !== 1  ?
                                    <div className="p-2 basket-count text-center">
                                      <Label for="count" className="font-weight-bold">Adet</Label>
                                      <InputNumber className="input-count w-100"  showButtons min={1} value={item.piece} onChange={e =>{
                                          if(e.value) {
                                            setValue('piece',e.value, index);
                                            setBasketPiece(index, e.value);
                                            if(item.isHisse) {
                                              if(item.hisseInfo.length > e.value) {
                                                setHisseRemove(index);
                                              } else if(item.hisseInfo.length < e.value) {
                                                let newHisseInfo = [...item.hisseInfo];
                                                newHisseInfo.push({nameSurname: null, phone: null, mail: null, nameError: false, phoneError: false, mailError: false});
                                                setValue('hisseInfo', newHisseInfo, index);
                                              }
                                            }
                                          }
                                      }}  />
                                    </div> 
                                    : null
                                  }
                                </div>
                              </Col>
                              <Divider className="basket-divider" layout="vertical" />
                              <Col xs="6" md="1" className="m-auto text-center" >
                                <Label className="basket-row-amount">{new Intl.NumberFormat('tr-TR', { currency: "TUR"  }).format(item.amount * item.piece) + " ₺"}</Label>
                              </Col>
                              {
                                !item.isHisse ? 
                                <Col xs="6" md="2" className="m-auto d-flex flex-column justify-content-center" >
                                  <label htmlFor={"binary" + index} className="m-auto">Başkasının Adına</label>
                                  <Checkbox inputId={"binary" + index} className="m-auto" checked={item.otherCheck ?? false} onChange={(e) => {
                                    setValue('otherCheck',e.checked, index);
                                    setValue('name','', index);
                                    setValue('phone','', index);
                                    setValue('mail','', index);
                                    setValue('otherNameError',false,index);
                                    setValue('otherPhoneError',false,index);
                                    setValue('otherMailError',false, index);
                                  }} />
                              </Col> : null
                              }
                              
                              <Col xs="12" md="1" className="d-flex justify-content-center align-items-center">
                                <Button className="basket-remove-btn" onClick={(event) => {confirmDelete(event, index)}}><BiTrash fontSize={20} /></Button>
                              </Col>
                            </Row>
                            <hr/>
                            {
                              item.isHisse  ?
                              item.hisseInfo && item.hisseInfo.map((event,i) =>{
                                
                                return <><Row><Col><label className="basketHisseTitle">{i+1}. Hisse Bilgileri</label></Col></Row><Row key={"row_other" + i}>
                                <Col xs="12" md="4"  className="pt-4 ">
                                  <span className="p-float-label">
                                    <InputText id="name" className={event.nameError ? "w-100 p-invalid" : "w-100"} 
                                    value={event.nameSurname} onChange={e=>setHisseValue('nameSurname',e.target.value,index,i)} 
                                    onBlur={e=>{if(e.target.value.length > 0) { setHisseValue('nameError',false,index,i) }}}
                                    />
                                    <label htmlFor="name">Ad Soyad</label>
                                  </span>
                                  {
                                    event.nameError ?  <small id="username-help" className="p-error">Ad Soyad alanı zorunludur</small> : null
                                  }
                                </Col>
                                <Col xs="12" md="4"  className="pt-4">
                                  <span className="p-float-label">
                                    <PhoneInput country={'tr'} id="tel" countryCodeEditable={false} className={event.phoneError ? "w-100 p-invalid-tel" : "w-100"} 
                                    value={event.phone} onChange={e=>setHisseValue('phone',e,index,i)} 
                                    onBlur={e=>{if(isPhone.test(event.phone)) { setHisseValue('phoneError',false,index,i) }}}
                                    />
                                    <label className="phoneLabel" htmlFor="tel">Telefon</label>
                                  </span>
                                  {
                                    event.phoneError ?  <small id="username-help" className="p-error">Telefon formatı yanlış</small> : null
                                  }
                                </Col>
                                <Col xs="12" md="4"  className="pt-4">
                                  <span className="p-float-label w-100">
                                    <InputText id="email" className={event.mailError ? "w-100 p-invalid" : "w-100"} 
                                    value={event.mail} onChange={e=>setHisseValue('mail',e.target.value.trim(),index,i)} 
                                    onBlur={e=>{if(isMail.test(e.target.value.trim())) { setHisseValue('mailError',false,index,i) }}}
                                    />
                                    <label htmlFor="email">E-Posta Adresi</label>
                                  </span>
                                  {
                                    event.mailError ?  <small id="username-help" className="p-error">E-Posta formatı yanlış</small> : null
                                  }
                                </Col>
                              </Row> <hr/> </>
                              })
                              : null
                            }
                            { item.otherCheck && !item.isHisse ? <><Row key={"row_other" + index}>
                              <Col xs="12" md="4"  className="pt-4 ">
                                <span className="p-float-label">
                                  <InputText id="name" className={item.otherNameError ? "w-100 p-invalid" : "w-100"} value={item.otherName} 
                                    onChange={(e) => {setValue('otherName',e.target.value, index)}} onBlur={e=>{if(e.target.value) { setValue('otherNameError',false,index)}}} />
                                  <label htmlFor="name">Ad Soyad</label>
                                </span>
                                {
                                  item.otherNameError ?  <small id="username-help" className="p-error">Ad Soyad alanı zorunludur</small> : null
                                }
                              </Col>
                              <Col xs="12" md="4"  className="pt-4">
                                <span className="p-float-label">
                                  <PhoneInput country={'tr'} id="tel" countryCodeEditable={false} className={item.otherPhoneError ? "w-100 p-invalid-tel" :"w-100"} value={item.otherPhone} 
                                    onChange={(e) => {setValue('otherPhone',e, index)}} onBlur={(e)=> {if(isPhone.test(item.otherPhone)) {setValue('otherPhoneError',false, index)}}}  />
                                  <label className="phoneLabel" htmlFor="tel">Telefon</label>
                                </span>
                                {
                                  item.otherPhoneError ?  <small id="username-help" className="p-error">Telefon formatı yanlış</small> : null
                                }
                              </Col>
                              <Col xs="12" md="4"  className="pt-4">
                                <span className="p-float-label w-100">
                                  <InputText id="email" className={item.otherMailError ? "w-100 p-invalid" : "w-100"} value={item.otherMail} 
                                    onChange={(e) => {setValue('otherMail',e.target.value.trim(), index)}} onBlur={e=>{if(isMail.test(e.target.value.trim())) { setValue('otherMailError',false, index)}}} />
                                  <label htmlFor="email">E-Posta Adresi</label>
                                </span>
                                {
                                  item.otherMailError ?  <small id="username-help" className="p-error">E-Posta formatı yanlış</small> : null
                                }
                              </Col>
                            </Row> <hr/> </>: null
                            }
                            </>)
                          })
                        }
                        {
                              isVekaletShow ? 
                              <Row>
                                <Col xs="12" className="m-auto" >
                                    <Checkbox inputId="vekaletInput" className="m-auto" checked={isVekaletAccept} onChange={(e) => {
                                      setIsVekaletAccept(e.checked)
                                    }} />
                                    <label htmlFor="vekaletInput" className="m-auto pl-2">Vekaletimi İnsanİzi Derneği'ne veriyorum.</label>

                                </Col>
                              </Row> : null
                            }
                        </>
                        }
                        
                        </Container> : selectedTab === 1 ?
                    <Container>
                        <Paytr  src={esnekPosSRC} />
                    </Container> : 
                    <Container>
                      {
                        paymentStatus ==='success' ? 
                        <div className="d-flex justify-content-center">
                          <div className=" text-center pr-4 pb-4">
                              <BiCheckCircle fontSize={100} color='#4BB543' />
                            <p className="payment-title">Bağışınız başarılı bir şekilde alınmıştır.</p>
                            <p className="payment-description">Yardımlarınız için teşekkür ederiz. </p>
                            <div>
                                <Button className="next-button" onClick={()=>{ window.location.href = 'https://insanizi.org/'}} > Anasayfaya Dön</Button>
                            </div>
                          </div>
                        </div>
                        : paymentStatus ==='fail' ? 
                        <div className="d-flex justify-content-center">
                          <div className=" text-center pr-4 pb-4">
                              <BiMinusCircle fontSize={100} color='#D0252B' />
                            <p className="payment-title">Ödeme Başarısız</p>
                            <p className="payment-description">Lütfen tekrar deneyiniz! </p>
                            <p className="payment-description" >{errorMessage}</p>
                            <div>
                                <Button className="next-button" onClick={()=>{ window.location.href = '/basket'}} > Sepete Dön</Button>
                            </div>
                          </div>
                        </div> : null
                      }
                        
                    </Container>
                  }
                  <Container>
                    {
                      basketList && basketList.length > 0 && selectedTab === 0 ?
                      <Row className="d-flex justify-content-end">
                        <Col xs="6" md="3">
                          <Label className="font-weight-bold" >Toplam Tutar: </Label>
                          <Label className="font-weight-bold ml-2 basket_total" >{new Intl.NumberFormat('tr-TR', { currency: "TUR"  }).format((basketList.reduce((a, b) => a + (b.piece * b.amount || 0), 0))) + " ₺"}</Label>
                        </Col>
                      </Row> : null

                    }
                      
                      <Row className="d-flex justify-content-end">
                        {
                          basketList && basketList.length>0 && selectedTab === 1 ?
                          <Col xs="3" md="1">
                            <Button onClick={()=>{
                                setSelectedTab(selectedTab-1)
                                window.scrollTo(0, 250)

                              }} >Geri</Button>
                          </Col> : null
                        }
                        {
                          basketList.length>0 && selectedTab == 0 ?
                          <Col xs="3" md="2">
                            <Button className="next-button" iconPos="right" label="İleri" loading={loading} onClick={nextStep}  />
                          </Col> : null
                        }
                        {/* {
                          selectedTab === 2 ? <Col xs="6" md="3">
                          <Button className="home_button" onClick={()=>{
                              window.location.href = '/';

                            }} >Anasayfaya Dön</Button>
                        </Col> : null
                        } */}
                      </Row>
                    </Container>
                  </>
                }
                
              </CardBody>
            </Card>
          </div>
        </section>
      );
}
export default Basket;
