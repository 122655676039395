export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  };
  
  // return the token from the session storage
  export const getToken = () => {
    return sessionStorage.getItem('token') || null;
  };
  
  // remove the token and user from the session storage
  export const  removeUser = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
  };
  
  // set the token and user from the session storage
  export const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
  };
  
  export const setCookie = (status) => {
    sessionStorage.setItem('setCookie', status);
  };
  
  export const getCookie = () => {
    return sessionStorage.getItem('setCookie');
  };
  
  
  // return the token from the session storage
  export const getSepet = () => {
    return JSON.parse(localStorage.getItem('sepet')) || [];
  };

  export const getBasketCount = () => {
    return localStorage.getItem('sepet') ? JSON.parse(localStorage.getItem('sepet')).length : 0;
  };
  
  // remove the token and user from the session storage
  export const  removeUserSession = () => {
    localStorage.removeItem('sepet');
  };
  
  // set the token and user from the session storage
  export const setSepet = (sepet) => {
    const oldproduct = localStorage.getItem('sepet') ? localStorage.getItem('sepet') : "[]";
    const arrayproduct =  JSON.parse(oldproduct);  
    
    arrayproduct.push(sepet);
    
    localStorage.setItem('sepet', JSON.stringify(arrayproduct));  
  };
  
  export const setSepetBosalt = () => {
    localStorage.removeItem('sepet');   
  };

  export const setBasketPiece = (id, value) => {
    const oldBasket = localStorage.getItem('sepet') ? JSON.parse(localStorage.getItem('sepet')) : [];
    if(oldBasket.length > 0) {
      oldBasket[id].piece = value;
    }
    localStorage.setItem('sepet', JSON.stringify(oldBasket));
  };

  export const setBasketAmount = (id, value) => {
    const oldBasket = localStorage.getItem('sepet') ? JSON.parse(localStorage.getItem('sepet')) : [];
    if(oldBasket.length > 0) {
      oldBasket[id].amount = value;
    }
    localStorage.setItem('sepet', JSON.stringify(oldBasket));
  };
  
  export const setPieceIncrement = (id) => {
    const oldproduct = localStorage.getItem('sepet') ? localStorage.getItem('sepet') : "[]";
    const arrayproduct =  JSON.parse(oldproduct);  
    arrayproduct[id].piece = arrayproduct[id].piece + 1;
    localStorage.setItem('sepet', JSON.stringify(arrayproduct));  
  };
  
  export const setPieceDecrement = (id) => {
    const oldproduct = localStorage.getItem('sepet') ? localStorage.getItem('sepet') : "[]";
    const arrayproduct =  JSON.parse(oldproduct);  
    if(arrayproduct[id].piece > 1){
      arrayproduct[id].piece = arrayproduct[id].piece - 1;
      localStorage.setItem('sepet', JSON.stringify(arrayproduct));  
    }
  };
  
  export const removeSepet = (id) => {
    let oldproduct = JSON.parse(localStorage.getItem('sepet'));
    if(id === 0 && oldproduct && oldproduct.length === 1 ){
      localStorage.setItem('sepet', JSON.stringify([])); 
    }
    else {
      let newpro = oldproduct.filter((items, itemIndex) => itemIndex !== id);
      localStorage.setItem('sepet', JSON.stringify(newpro)); 
    }
  
  };
  