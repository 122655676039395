import RestConstants from "./RestConstants";
import { confirmDialog } from 'primereact/confirmdialog';

let Remotes = {

  getDonationType: async () => {
    const url = process.env.REACT_APP_DONATIONS;
    try {
      const r = await RestConstants.jFetch(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  saveDonation: async (response) => {
    const url = process.env.REACT_APP_SAVE_DONATION;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  smsPassword: async (response) => {
    const url = process.env.REACT_APP_SMS_PASSWORD;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  smsPasswordControl: async (response) => {
    const url = process.env.REACT_APP_SMS_PASSWORD_CONTROL;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  payToken: async (response) => {
    const url = process.env.REACT_APP_PAY_URL;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  payTokenEsnekPos: async (response) => {
    const url = process.env.REACT_APP_ESNEK_POS_PAY_URL;
    try {
      const r = await RestConstants.jPost(url, response);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },
  getProfile: async () => {
    const url = process.env.REACT_APP_GET_PROFILE;
    try {
      const r = await RestConstants.jFetch(url);
      return r;
    } catch (err) {
      Remotes.handleError(err);
    }
  },

  handleError: (err) => {
    if(err.statusCode === 400) {
      confirmDialog({
        message: err.response.data.Message,
        header: 'Hata',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Tamam',
        rejectClassName: 'hidden',
        
      });

    } else if(err.statusCode === 401) {
      confirmDialog({
        message: 'Giriş süresi doldu. Lüften tekrar giriş yapınız!',
        header: 'Hata',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Tamam',
        rejectClassName: 'hidden',
        accept: ()=>{
          sessionStorage.clear();
          window.location.href = '/giris';
        }
        
      });


    } else if(err && err.response && err.response.data && err.response.data.error_description) {
      confirmDialog({
        message: err.response.data.error_description,
        header: 'Hata',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Tamam',
        rejectClassName: 'hidden',
        
      });
    } else  {
        confirmDialog({
          message: 'Bir Hata Oluştu.',
          header: 'Hata',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          acceptLabel: 'Tamam',
          rejectClassName: 'hidden'
      });
    }
    throw err;

  }
};

export default Remotes;
