import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles for this kit
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import "assets/css/bootstrap.min.css";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import 'primeflex/primeflex.css';
import 'react-phone-input-2/lib/bootstrap.css';
// pages for this kit

import DarkFooter from "components/Footers/DarkFooter";
import IndexNavbar from "components/Navbars/IndexNavbar";
import { Provider } from "react-redux";
import { Redirect } from "react-router-dom";
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { createPromise } from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { getCookie } from "store/common";
import rootReducer from "store/store/reducers/rootReducer";
import AccountNumbers from "views/AccountNumbers";
import Contact from "views/Contact";
import Donations from "views/Donations";
import Login from "views/Login";
import Profile from "views/Profile";
import Basket from "views/basket/Basket";
import Kurban from "views/kurban";
import whatsappLogo from "./assets/img/whatsapp.png";
import { FaWhatsapp } from "react-icons/fa";
import { FloatingWhatsApp } from "components/FloatingWhatsApp/FloatingWhatsApp";

const store = createStore(
	rootReducer,
	applyMiddleware(createPromise(), thunk, createLogger())
);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <IndexNavbar />
        <Switch>
          <Route path="/" exact render={(props) => <Donations {...props} />} />
          <Route path="/basket" exact render={(props) => <Basket {...props} />} />
          <Route path="/basket/:pay" exact render={(props) => <Basket {...props} />} />
          <Route path="/hesap_numaralarimiz" exact render={(props) => <AccountNumbers {...props} />} />
          <Route path="/kurban" exact render={(props) => <Kurban {...props} />} />
          <Route path="/contact" exact render={(props) => <Contact {...props} />} />


          <Route path="/giris" exact render={(props) =>{
            if(getCookie()) {
              return <Redirect to="/profil" />
            } else {
              return <Login {...props} />
            }

          }} />
          <Route path="/profil" exact render={(props) =>{
            if(getCookie()) {
              return <Profile {...props} />
            } else {
              return <Redirect to="/giris" />
            }

          }} /> 

        </Switch>
      <DarkFooter />
      <FloatingWhatsApp />
      
      {/* <div className="whatsapp-btn">
        <a
          href="https://web.whatsapp.com/send?phone=905308198100"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp size="50px" color="white" />
        </a>
      </div> */}
    </Provider>
  </BrowserRouter>
  ,
  document.getElementById("root")
);
