import {
    ADD_PASSWORD,
    ADD_PHONE,
    GET_DONATION_LIST,
    GET_USERNAME,
    LOGIN_ADD,
    REMOVE_USER
  } 
  from '../constans';
  const initialState = {
    donationList: [],
    username:  null,
    password:null,
    phone: null
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_ADD:
            if(JSON.parse(localStorage.getItem("donationList") == null)){
                return{
                    ...state,
                    donationList: action.payload
                }
            }
            else{
                return{
                    ...state,
                    donationList: JSON.parse(localStorage.getItem("donationList"))
                }
            }
        case ADD_PHONE:
            if(JSON.parse(localStorage.getItem("phone") == null)){
                return{
                ...state,
                phone: action.payload 
                }
            }
            else{
                return{
                ...state,
                phone: JSON.parse(localStorage.getItem("phone")),
                }
            }
        case ADD_PASSWORD:
            if(JSON.parse(localStorage.getItem("password") == null)){
                return{
                ...state,
                password: action.payload 
                }
            }
            else{
                return{
                ...state,
                password: JSON.parse(localStorage.getItem("password")),
                }
            }
        case GET_DONATION_LIST:
            return{
                ...state,
                donationList: action.payload
            }
        case REMOVE_USER:
            localStorage.removeItem('donationList');
            localStorage.removeItem('password');
            localStorage.removeItem('phone');
            return{
                ...state,
                donationList: [],
                phone: null,
                password: null,
                username: null
            }
        case GET_USERNAME:
            if(JSON.parse(localStorage.getItem("donationList") == null)){
                return{
                ...state,
                username: action.payload 
                }
            }
            else{
                return{
                ...state,
                username: JSON.parse(localStorage.getItem("donationList")),
                }
            }
     
  
      default:
        return state;
    }
  }