/*eslint-disable*/
import React from "react";

// reactstrap components
import { Col, Container, Row } from "reactstrap";
import logo  from 'assets/img/logo_white.svg';
import { FaFacebookSquare, FaTwitterSquare, FaInstagram } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { MdSupportAgent, MdNearMe, MdMap, MdEmail } from "react-icons/md";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <Row>
          <Col xs="12" md="4" className="p-5">
            <div>
              <img src={logo} className="footer_logo" alt="İnsanizi Derneği" />
              <p className="footer_description">İnsan İzi Uluslararası İnsani Yardım Derneği, geçmişte bir çok insani yardım organizasyonuna emek vermiş insanların ‘iyilikte iz bırakmak’ mottosuyla kurdukları yeni nesil yardımlaşma platformudur.</p>
              <p className="footer_social_text">Sosyal Medya hesaplarımızı takip edebilirsiniz.</p>
              <a href='https://www.facebook.com/insaniziorg/' className="ml-2" ><FaFacebookSquare fontSize={30} /></a>
              <a href='https://www.twitter.com/insaniziorg/' className="ml-2" ><FaTwitterSquare fontSize={30} /></a>
              <a href='https://www.instagram.com/insaniziorg/' className="ml-2" ><FaInstagram fontSize={30} /></a>
            </div>
          </Col>
          <Col xs="12" md="4" className="p-5">
            <div>
              <MdEmail fontSize={30} />
              <p className="footer_email">E-Posta</p>
              <p className="footer_mail_text">Aklınıza takılan sorulara samimi cevaplar almak için bize yazmaktan çekinmeyiniz.</p>
              <a href="mailto:name@email.com"><p className="footer_mail_info"><IoSend className="mr-2"/>info@insanizi.org</p></a>

              <MdSupportAgent fontSize={30}  />
              <p className="footer_email">İletişim</p>
              <p>Bizimle iletişim kurmaktan çekinmeyiniz.</p>
              <a href="tel:08505322540"><p className="font-weight-bold">0 850 532 25 40</p></a>

            </div>
          </Col>
          <Col xs="12" md="4" className="p-5">
            <div>
              <MdNearMe fontSize={30}  />
              <p className="footer_email">Adres</p>
              <p>Aziz Mahmut Hüdayi Mahallesi Mehmet Paşa Değirmeni Sok. No.30  Üsküdar/İstanbul</p>

              <MdMap fontSize={30} />
              <p className="footer_email">Harita</p>
              <a href="https://goo.gl/maps/UMqM9SqXi7TFBTsW8" target="_blank"><p>Yol Tarifi</p></a>

            </div>
          </Col>
        </Row>
       
        {/* <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, 
          Coded by{" "}
          <a
            href="mailto:nailkamit84@gmail.com"
          >
            Nail Kamit
          </a>
          .
        </div> */}
      </Container>
    </footer>
  );
}

export default DarkFooter;
